import { ArrowLeft } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { Surelevation } from '../models/surelevation';
import { laCarteService } from '../services/la-carte.service';
import { SurelevationCard } from './surelevation-card';
import './uppy-search-displayer.scss';

export const UppySearchDisplayer: React.FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [displayedSurelevation, setDisplayedSurelevation] =
    useState<Surelevation>();

  let alreadyDisplayed = false;

  const [animationState, setAnimationState] = useState<string>();

  const surelevationCardContainerRef = React.useRef<HTMLDivElement>(null);

  let toBeSettedSurelevation: Surelevation;

  useEffect(() => {
    const subscription = laCarteService.selectedSurelevation$.subscribe(
      (surelevation) => selectedSurelevationChanged(surelevation)
    );

    surelevationCardContainerRef.current?.addEventListener(
      'animationend',
      handleSurelevationCardContainerAnimationEnd
    );
    return () => {
      subscription.unsubscribe();

      surelevationCardContainerRef.current?.removeEventListener(
        'animationend',
        handleSurelevationCardContainerAnimationEnd
      );
    };
  }, []);

  function selectedSurelevationChanged(surelevation?: Surelevation) {
    let isOpened = false;

    if (surelevation) {
      isOpened = true;
      if (alreadyDisplayed) {
        toBeSettedSurelevation = surelevation;
        setAnimationState('fadeout');
      } else {
        setDisplayedSurelevation(surelevation);
      }
    }

    setIsOpened(isOpened);
    alreadyDisplayed = isOpened;
  }

  function handleSurelevationCardContainerAnimationEnd(event: AnimationEvent) {
    if (event.animationName === 'fadeout') {
      setDisplayedSurelevation(toBeSettedSurelevation);
      setAnimationState('fadein');
    }
    if (event.animationName === 'fadein') {
      setAnimationState(undefined);
    }
  }

  function handleBackToSearchClick() {
    laCarteService.selectedSurelevation$.next(undefined);
  }

  return (
    <div
      className={
        'uppy-search-displayer' +
        (isOpened ? ' uppy-search-displayer--opened' : '')
      }
    >
      <div
        className="uppy-search-displayer__back-to-search"
        onClick={handleBackToSearchClick}
      >
        <ArrowLeft className="uppy-search-displayer__back-to-search__arrow" />
        <span className="uppy-search-displayer__back-to-search__text">
          Retour aux résultats
        </span>
      </div>

      <div className="uppy-search__section">
        <div className="uppy-search__section__header">
          <div className="uppy-search__section__header__title">
            En cours de consultation
          </div>
        </div>

        <div className="uppy-search__section__content">
          <div
            ref={surelevationCardContainerRef}
            className={
              'uppy-search-displayer__container' +
              (animationState
                ? ' uppy-search-displayer__container--' + animationState
                : '')
            }
          >
            {displayedSurelevation && (
              <SurelevationCard
                surelevation={displayedSurelevation}
                detailed={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
