import React, { useEffect, useRef, useState } from 'react';
import { ArrowCounterClockwise } from 'phosphor-react';
import './uppy-map.scss';
import maplibregl, { LngLatBoundsLike, Map } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Surelevation } from '../models/surelevation';
import arrondissementGeoJson from '../data/arrondissements.json';

import uppyPin from '../assets/images/uppy-pin.svg';
import { Arrondissement } from '../models/arrondissement';
import { laCarteService } from '../services/la-carte.service';
import { Subscription } from 'rxjs';

interface LightLayer {
  id: string;
  type: string;
  baseOpacity: number;
  arrondissement: number;
}

interface UppyMarker {
  marker: maplibregl.Marker;
  element: HTMLElement;
  arrondissement: number;
  id?: string;
}

interface UppyMapProps {
  arrondissements: Arrondissement[];
}

export const UppyMap: React.FC<UppyMapProps> = ({ arrondissements }) => {
  const mapRef = useRef<HTMLDivElement>(null);

  let map: Map;
  let mapLoaded = false;
  let mainLayers: LightLayer[] = [];
  let mainMarkers: UppyMarker[] = [];
  let surelevationMarkers: UppyMarker[] = [];
  let sources: string[] = [];

  const INITIAL_BOUNDS: LngLatBoundsLike = [
    { lon: 2.247734, lat: 48.812742 },
    { lon: 2.421455, lat: 48.906028 },
  ];

  const [mobileMapView, setMobileMapView] = useState<boolean>();
  const [surelevations, setSurelevations] = useState<Surelevation[]>();

  useEffect(() => {
    map = new maplibregl.Map({
      container: mapRef.current!,
      style:
        'https://api.maptiler.com/maps/pastel/style.json?key=btRtiu2RXNP41CHn2jnG',
      bounds: INITIAL_BOUNDS,
    });

    map.on('load', () => {
      mapLoaded = true;
      drawArrondissements();
    });

    const subscriptions: Subscription[] = [];

    subscriptions.push(
      laCarteService.currentSurelevations$.subscribe((surelevations) => {
        setSurelevations(surelevations);
        if (surelevations) {
          cleanMap();
          drawSurelevations(surelevations);
        }
      })
    );

    subscriptions.push(
      laCarteService.selectedSurelevation$.subscribe((surelevations) => {
        surelevationMarkers.forEach((marker) => {
          marker.element.classList.remove('uppy-map__pin--selected');
          marker.element.classList.remove('uppy-map__pin--not-selected');
          if (surelevations) {
            if (marker.id === surelevations.id) {
              marker.element.classList.add('uppy-map__pin--selected');
              map.flyTo({ center: surelevations.coordinates });
            } else {
              marker.element.classList.add('uppy-map__pin--not-selected');
            }
          }
        });
      })
    );

    subscriptions.push(
      laCarteService.resetFilters$.subscribe(() => {
        cleanMap();
        drawArrondissements();
        map.fitBounds(INITIAL_BOUNDS);
      })
    );

    subscriptions.push(
      laCarteService.mobileMapView$.subscribe(setMobileMapView)
    );

    return () => {
      map.remove();
      subscriptions.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  useEffect(() => {
    drawArrondissements();
  }, [arrondissements]);

  function selectArrondissement(arrondissement: Arrondissement) {
    laCarteService.forceArrondissementFilter$.next(arrondissement);
  }

  function cleanMap() {
    mainLayers.forEach((layer) => {
      map.removeLayer(layer.id);
    });
    mainLayers = [];
    mainMarkers.forEach((marker) => {
      marker.marker.remove();
    });
    mainMarkers = [];
    surelevationMarkers.forEach((marker) => {
      marker.marker.remove();
    });
    surelevationMarkers = [];
  }

  function drawSurelevations(surelevations: Surelevation[]) {
    if (!surelevations) {
      return;
    }
    surelevations.sort((a, b) => b.coordinates.lat - a.coordinates.lat);
    for (const surelevation of surelevations) {
      const el = getUppyMarker();

      el.addEventListener('click', () => {
        laCarteService.selectedSurelevation$.next(surelevation);
      });

      surelevationMarkers.push({
        marker: new maplibregl.Marker(el)
          .setLngLat(surelevation.coordinates)
          .addTo(map),
        element: el,
        arrondissement: surelevation.arrondissement,
        id: surelevation.id,
      });
    }

    if (surelevations.length) {
      const allSurelevationLats = surelevations.map((surelevation) => {
        return surelevation.coordinates.lat;
      });

      const allSurelevationLons = surelevations.map((surelevation) => {
        return surelevation.coordinates.lon;
      });

      const bounds: LngLatBoundsLike = [
        {
          lon: Math.max(...allSurelevationLons),
          lat: Math.max(...allSurelevationLats),
        },
        {
          lon: Math.min(...allSurelevationLons),
          lat: Math.min(...allSurelevationLats),
        },
      ];

      map.fitBounds(bounds, { padding: 50 });
    }
  }

  function getArrondissementGeoDataByNumber(arrondissementNumber: number) {
    return arrondissementGeoJson.find(
      (arrond) => arrond.fields.c_ar === arrondissementNumber
    );
  }

  function getUppyMarker(number?: number) {
    const el = document.createElement('div');
    const img = document.createElement('img');
    el.className = 'uppy-map__pin';
    img.className = 'uppy-map__pin__img';
    img.src = uppyPin;

    el.appendChild(img);
    if (number) {
      const elNumber = document.createElement('div');
      elNumber.className = 'uppy-map__pin__number';
      elNumber.textContent = number + '';
      el.appendChild(elNumber);
    } else {
      const selectedEl = document.createElement('div');
      selectedEl.className = 'uppy-map__pin__is-selected';
      selectedEl.textContent = 'Vous consultez actuellement cette opportunité';
      el.appendChild(selectedEl);
    }
    return el;
  }

  function drawArrondissements() {
    if (!mapLoaded || !arrondissements) {
      return;
    }

    for (const arrondissement of arrondissements) {
      const arrondissementGeoData = getArrondissementGeoDataByNumber(
        arrondissement.number
      );
      if (!arrondissementGeoData) {
        continue;
      }
      const arrondissementNumber = arrondissementGeoData.fields.c_ar;
      const id = 'arrondissement' + arrondissementNumber;

      if (!sources.includes(id)) {
        sources.push(id);
        map.addSource(id, {
          type: 'geojson',
          data: arrondissementGeoData.fields.geom,
        });
      }

      const fillLayer: LightLayer = {
        id: id + '-fill',
        type: 'fill',
        baseOpacity: 0.3,
        arrondissement: arrondissementNumber,
      };
      const borderLayer: LightLayer = {
        id: id + '-border',
        type: 'line',
        baseOpacity: 0.7,
        arrondissement: arrondissementNumber,
      };
      mainLayers.push(fillLayer, borderLayer);
      map.addLayer({
        ...fillLayer,
        source: id,
        layout: {},
        paint: {
          'fill-color': '#f5ba9b',
          'fill-opacity': fillLayer.baseOpacity,
          'fill-opacity-transition': { duration: 400 },
        },
      } as maplibregl.FillLayer);
      map.addLayer({
        ...borderLayer,
        source: id,
        layout: {},
        paint: {
          'line-color': '#6a8ebf',
          'line-width': 2,
          'line-opacity': borderLayer.baseOpacity,
          'line-opacity-transition': { duration: 400 },
        },
      } as maplibregl.LineLayer);
      // add marker to map
      const el = getUppyMarker(arrondissement.surelevations);

      el.addEventListener('click', () => {
        selectArrondissement(arrondissement);
      });

      mainMarkers.push({
        marker: new maplibregl.Marker(el)
          .setLngLat(
            arrondissementGeoData.geometry.coordinates as [number, number]
          )
          .addTo(map),
        element: el,
        arrondissement: arrondissementNumber,
      });
    }
  }

  function handleResetFilterClick() {
    laCarteService.resetFilters$.next();
  }

  return (
    <div
      className={
        'uppy-map' + (mobileMapView ? ' uppy-map--mobile-in-front' : '')
      }
    >
      <div className="uppy-map__map" ref={mapRef}></div>
      {surelevations && (
        <div
          className="uppy-button uppy-button--shadow uppy-map__reset-button"
          onClick={handleResetFilterClick}
        >
          <ArrowCounterClockwise className="uppy-button__icon" />
          Réinitialiser les filtres
        </div>
      )}
    </div>
  );
};
