import { Pencil } from 'phosphor-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Select, { Options } from 'react-select';
import { Subscription } from 'rxjs';
import { Arrondissement } from '../models/arrondissement';
import {
  ProprietaryType,
  proprietaryTypeTranslations,
} from '../models/proprietary-type';
import { Surelevation } from '../models/surelevation';
import { SurelevationsParams } from '../models/surelevations-params';
import { laCarteService } from '../services/la-carte.service';
import { StringUtils } from '../utils/string-utils';
import { SurelevationCard } from './surelevation-card';
import { UppySearchDisplayer } from './uppy-search-displayer';
import './uppy-search.scss';
import uppyPinDead from '../assets/images/uppy-pin-dead.svg';

interface UppySearchProps {
  arrondissements: Arrondissement[];
}

interface ProprietaryTypeOption {
  value: ProprietaryType;
  label: string;
}

type Marks = Record<
  number,
  {
    label?: string;
  }
>;

const Range = Slider.createSliderWithTooltip(Slider.Range);

export const UppySearch: React.FC<UppySearchProps> = ({ arrondissements }) => {
  const [searchOpened, setSearchOpened] = useState(true);
  const [selectedArrondissement, setSelectedArrondissement] =
    useState<Options<Arrondissement>>();

  const surfaceMin = 0;
  const surfaceMax = 3000;
  const [surfaceValue, setSurfaceValue] = useState<number[]>([
    surfaceMin,
    surfaceMax,
  ]);
  const surfaceMarks = getSurfaceMarks();

  const elevationLevelsMin = 1;
  const elevationLevelsMax = 7;
  const [elevationLevelsValue, setElevationLevelsValue] = useState<number[]>([
    elevationLevelsMin,
    elevationLevelsMax,
  ]);
  const elevationLevelsMarks = getElevationLevelsMarks();

  const [surelevations, setSurelevations] = useState<Surelevation[]>();

  useEffect(() => {
    const subscriptions: Subscription[] = [];
    subscriptions.push(
      laCarteService.currentSurelevations$.subscribe((surelevations) => {
        setSurelevations(surelevations);
        if (surelevations) {
          setSearchOpened(false);
        }
      })
    );
    subscriptions.push(
      laCarteService.forceArrondissementFilter$.subscribe((arrondissement) => {
        setSelectedArrondissement([arrondissement]);
        submitSearch([arrondissement]);
      })
    );
    subscriptions.push(
      laCarteService.resetFilters$.subscribe(() => {
        setSelectedArrondissement([]);
        setSurfaceValue([surfaceMin, surfaceMax]);
        setElevationLevelsValue([elevationLevelsMin, elevationLevelsMax]);
        setSelectedProprietaryType([]);
        setSearchOpened(true);
      })
    );
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const proprietaryTypeOptions: ProprietaryTypeOption[] = Object.values(
    ProprietaryType
  ).map((value) => ({ value, label: proprietaryTypeTranslations[value] }));

  const [selectedProprietaryType, setSelectedProprietaryType] =
    useState<Options<ProprietaryTypeOption>>();

  const [canSearch, setCanSearch] = useState(false);

  function handleSearchSectionHeaderClick() {
    if (surelevations) {
      setSearchOpened(!searchOpened);
    }
  }

  function getSurfaceMarks(): Marks {
    const marks: Marks = {};
    for (let i = surfaceMin; i <= surfaceMax; i = i + 1000) {
      marks[i] = { label: StringUtils.meterSquareFormater(i) };
    }
    return marks;
  }

  function getElevationLevelsMarks(): Marks {
    const marks: Marks = {};
    for (let i = elevationLevelsMin; i <= elevationLevelsMax; i++) {
      marks[i] = { label: i.toString() };
    }
    return marks;
  }

  function handleModifySearchClick() {
    setSearchOpened(true);
  }

  function handleSearchClick() {
    submitSearch(selectedArrondissement);
  }

  function submitSearch(selectedArrondissement?: Options<Arrondissement>) {
    const params: SurelevationsParams = {};

    if (selectedArrondissement?.length) {
      params.arrondissements = selectedArrondissement.map(
        (arrondissement) => arrondissement.number
      );
    }
    if (selectedProprietaryType?.length) {
      params.proprietaryTypes = selectedProprietaryType.map(
        (proprietaryType) => proprietaryType.value
      );
    }
    if (surfaceValue[0] !== surfaceMin) {
      params.surfaceMin = surfaceValue[0];
    }
    if (surfaceValue[1] !== surfaceMax) {
      params.surfaceMax = surfaceValue[1];
    }
    if (elevationLevelsValue[0] !== elevationLevelsMin) {
      params.elevationLevelsMin = elevationLevelsValue[0];
    }
    if (elevationLevelsValue[1] !== elevationLevelsMax) {
      params.elevationLevelsMax = elevationLevelsValue[1];
    }
    laCarteService.fetchSurelevations(params);
  }

  useEffect(() => {
    let canSearch = false;
    if (selectedArrondissement?.length) {
      canSearch = true;
    }
    if (selectedProprietaryType?.length) {
      canSearch = true;
    }
    if (surfaceValue[0] !== surfaceMin || surfaceValue[1] !== surfaceMax) {
      canSearch = true;
    }
    if (
      elevationLevelsValue[0] !== elevationLevelsMin ||
      elevationLevelsValue[1] !== elevationLevelsMax
    ) {
      canSearch = true;
    }
    setCanSearch(canSearch);
  }, [
    selectedArrondissement,
    selectedProprietaryType,
    surfaceValue,
    elevationLevelsValue,
  ]);

  return (
    <div className="uppy-search">
      <div className="uppy-search__search">
        <div className="uppy-search__section">
          <div
            className={
              'uppy-search__section__header uppy-search__section__header--search uppy-search__search-header' +
              (!surelevations ? ' uppy-search__search-header--no-click' : '')
            }
            onClick={handleSearchSectionHeaderClick}
          >
            <div className="uppy-search__section__header__title">
              Ma recherche
            </div>
            <div className="uppy-search__search-header__edit">
              <Pencil className="uppy-search__search-header__edit__pencil" />
            </div>
            <div className="uppy-search__search-header__tooltip">
              Modifier ma recherche
            </div>
          </div>
          <AnimateHeight height={searchOpened ? 'auto' : 0} duration={400}>
            <div className="uppy-search__section__content">
              <div className="uppy-field">
                <label htmlFor="arrondissements">Arrondissement</label>

                <Select
                  name="arrondissements"
                  options={arrondissements}
                  getOptionLabel={(option) => 'Paris ' + option.number}
                  getOptionValue={(option) => option.number.toString()}
                  isMulti
                  onChange={setSelectedArrondissement}
                  value={selectedArrondissement}
                  placeholder="Sélectionnez..."
                  classNamePrefix="uppy-react-select"
                />
              </div>

              <div className="uppy-field">
                <label htmlFor="surface">
                  Surface potentielle à créer en m²
                </label>

                <div className="uppy-search__slider">
                  <Range
                    allowCross={false}
                    value={surfaceValue}
                    min={surfaceMin}
                    max={surfaceMax}
                    onChange={setSurfaceValue}
                    marks={surfaceMarks}
                    tipFormatter={StringUtils.meterSquareFormater}
                    step={20}
                  />
                </div>
              </div>

              <div className="uppy-field">
                <label htmlFor="elevationLevel">
                  Nombre d'étages potentiels à créer
                </label>

                <div className="uppy-search__slider">
                  <Range
                    allowCross={false}
                    value={elevationLevelsValue}
                    min={elevationLevelsMin}
                    max={elevationLevelsMax}
                    onChange={setElevationLevelsValue}
                    marks={elevationLevelsMarks}
                  />
                </div>
              </div>

              <div className="uppy-field">
                <label htmlFor="proprietaryType">Type de propriétaire</label>

                <Select
                  name="proprietaryType"
                  options={proprietaryTypeOptions}
                  isMulti
                  onChange={setSelectedProprietaryType}
                  value={selectedProprietaryType}
                  placeholder="Sélectionnez..."
                />
              </div>
              <button
                className="uppy-search__submit uppy-button uppy-button--primary"
                disabled={!canSearch}
                onClick={handleSearchClick}
              >
                Lancer ma recherche
              </button>
            </div>
          </AnimateHeight>
        </div>

        {surelevations && (
          <div className="uppy-search__section">
            <div className="uppy-search__section__header">
              <div className="uppy-search__section__header__title">
                Mes résultats
              </div>
              <div className="uppy-search__section__header__number">
                {surelevations.length}
              </div>
            </div>

            <div className="uppy-search__section__content">
              {surelevations.map((surelevation) => (
                <SurelevationCard
                  surelevation={surelevation}
                  key={surelevation.id}
                />
              ))}
              {surelevations.length === 0 && (
                <div className="uppy-search__no-result">
                  <img
                    src={uppyPinDead}
                    className="uppy-search__no-result__image"
                  />
                  <div className="uppy-search__no-result__title">
                    Désolé, nous n'avons trouvé aucune annonce qui correspondent
                    à vos critères.
                  </div>
                  <div className="uppy-search__no-result__text">
                    Pour davantage de résultats, élargissez vos critères de
                    recherche, ou bien repassez fréquemment. Nous mettons à jour
                    nos opportunités toutes les semaines, sans faute !
                  </div>
                  <div
                    onClick={handleModifySearchClick}
                    className="uppy-button uppy-button--primary uppy-search__no-result__button"
                  >
                    Modifier ma recherche
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <UppySearchDisplayer />
    </div>
  );
};
