import axios from 'axios';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Arrondissement } from '../models/arrondissement';
import { Surelevation } from '../models/surelevation';
import { SurelevationsParams } from '../models/surelevations-params';

class LaCarteService {
  currentSurelevations$ = new BehaviorSubject<Surelevation[] | undefined>(
    undefined
  );
  selectedSurelevation$ = new BehaviorSubject<Surelevation | undefined>(
    undefined
  );
  mobileMapView$ = new BehaviorSubject<boolean>(false);

  forceArrondissementFilter$ = new Subject<Arrondissement>();
  resetFilters$ = new Subject<void>();

  constructor() {
    this.resetFilters$.subscribe(() => {
      this.currentSurelevations$.next(undefined);
      this.selectedSurelevation$.next(undefined);
    });
  }

  fetchSurelevations(params: SurelevationsParams) {
    axios
      .get(process.env.GATSBY_API_URL + '/surelevations', { params })
      .then((res) => {
        this.currentSurelevations$.next(res.data);
      });
  }
}

export const laCarteService = new LaCarteService();
