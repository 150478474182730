import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { UppyMap } from '../components/uppy-map';
import { UppySearch } from '../components/uppy-search';
import { Seo } from '../components/seo';
import { ISeo } from '../models/seo';
import './la-carte.scss';
import logo from '../assets/images/logo.svg';
import axios from 'axios';
import { Arrondissement } from '../models/arrondissement';
import {
  ArrowLeft,
  MapPin,
  ListDashes,
  ArrowCounterClockwise,
} from 'phosphor-react';
import { Subscription } from 'rxjs';
import { Surelevation } from '../models/surelevation';
import { laCarteService } from '../services/la-carte.service';

interface LaCartePageData {
  strapiLaCarte: {
    seo: ISeo;
  };
}

const LaCartePage: React.FC = () => {
  const data: LaCartePageData = useStaticQuery(query);
  const laCarte = data.strapiLaCarte;
  const [arrondissements, setArrondissements] = useState<Arrondissement[]>();
  const [surelevations, setSurelevations] = useState<Surelevation[]>();
  const [selectedSurelevations, setSelectedSurelevation] =
    useState<Surelevation>();
  const [mobileMapView, setMobileMapView] = useState<boolean>();

  useEffect(() => {
    axios.get(process.env.GATSBY_API_URL + '/arrondissements').then((res) => {
      const arr: Arrondissement[] = res.data;
      arr.sort((a, b) => a.number - b.number);
      setArrondissements(arr);
    });
    const subscriptions: Subscription[] = [];
    subscriptions.push(
      laCarteService.currentSurelevations$.subscribe(setSurelevations)
    );
    subscriptions.push(
      laCarteService.mobileMapView$.subscribe(setMobileMapView)
    );
    subscriptions.push(
      laCarteService.selectedSurelevation$.subscribe(setSelectedSurelevation)
    );
    return () => {
      subscriptions.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  function handleListViewClick() {
    laCarteService.mobileMapView$.next(false);
  }

  function handleMapViewClick() {
    laCarteService.mobileMapView$.next(true);
  }

  function handleResetFilterClick() {
    laCarteService.resetFilters$.next();
  }

  return (
    <div className="la-carte">
      <Seo seo={laCarte.seo} />
      <div className="la-carte__header">
        <a
          href="/"
          className="uppy-button uppy-button--shadow la-carte__header__back-to-site"
        >
          <ArrowLeft className="uppy-button__icon" />
          Revenir vers le site
        </a>
        <a href="/">
          <img src={logo} alt="Uppy" className="la-carte__header__logo" />
        </a>
        <span className="la-carte__header__catch">
          By Lanquetin &amp; Associés - Géomètres-Experts
        </span>
      </div>
      <div className="la-carte__content">
        {arrondissements && (
          <>
            <UppyMap arrondissements={arrondissements} />
            <UppySearch arrondissements={arrondissements} />
          </>
        )}
      </div>
      <div
        className={
          'la-carte__mobile-buttons' +
          (selectedSurelevations ? ' la-carte__mobile-buttons--hidden' : '')
        }
      >
        {surelevations && (
          <div
            className="uppy-button uppy-button--shadow"
            onClick={handleResetFilterClick}
          >
            <ArrowCounterClockwise className="uppy-button__icon" />
            Réinitialiser les filtres
          </div>
        )}
        {mobileMapView ? (
          <div
            className="uppy-button uppy-button--shadow  uppy-map__buttons__phone"
            onClick={handleListViewClick}
          >
            <ListDashes className="uppy-button__icon" />
            {surelevations ? 'Vue' : 'Passer en vue'} <b>Liste</b>
          </div>
        ) : (
          <div
            className="uppy-button uppy-button--shadow  uppy-map__buttons__phone"
            onClick={handleMapViewClick}
          >
            <MapPin className="uppy-button__icon" />
            {surelevations ? 'Vue' : 'Passer en vue'} <b>Carte</b>
          </div>
        )}
      </div>
    </div>
  );
};

const query = graphql`
  query {
    strapiLaCarte {
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
    }
  }
`;

export default LaCartePage;
