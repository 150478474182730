import axios from 'axios';
import { CheckCircle } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { Surelevation } from '../models/surelevation';
import { laCarteService } from '../services/la-carte.service';

import { StringUtils } from '../utils/string-utils';
import './surelevation-card.scss';

type InputType = string | undefined;

interface IFormValues {
  firstName: InputType;
  lastName: InputType;
  phone: InputType;
  email: InputType;
}

interface SurelevationCardProps {
  surelevation: Surelevation;
  detailed?: boolean;
}

export const SurelevationCard: React.FC<SurelevationCardProps> = ({
  surelevation,
  detailed,
}) => {
  function handleSeeMoreDetailsClick() {
    laCarteService.selectedSurelevation$.next(surelevation);
  }

  function handleAcquireProjectClick() {
    setFormDisplayed(true);
  }

  function handleReturnToTechnicalInfosClick() {
    setFormDisplayed(false);
  }

  function handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormValues({ ...formValues, firstName: event.target.value });
  }
  function handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormValues({ ...formValues, lastName: event.target.value });
  }
  function handleEmailNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormValues({ ...formValues, email: event.target.value });
  }
  function handlePhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormValues({ ...formValues, phone: event.target.value });
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    axios
      .post(process.env.GATSBY_API_URL + '/contact', {
        ...formValues,
        surelevationId: surelevation.id,
      })
      .then(() => {
        setSubmitSuccess(true);
      });
  }

  const [priseAuSol, setPriseAuSol] = useState<number>();

  const [formDisplayed, setFormDisplayed] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<IFormValues>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

  useEffect(() => {
    setPriseAuSol(Math.round(Math.random() * 100) + 20);
  }, []);

  useEffect(() => {
    setFormDisplayed(false);
  }, [surelevation]);

  return (
    <div
      className={
        'surelevation-card' + (detailed ? ' surelevation-card--detailed' : '')
      }
    >
      <div className="surelevation-card__title">
        Surélévation Uppy
        <span className="surelevation-card__title__bar"> | </span>
        Paris {surelevation.arrondissement}
      </div>
      <div className="surelevation-card__reference">
        Référence : {StringUtils.getFirstPartOfUUID(surelevation.id)}
      </div>
      {!formDisplayed ? (
        <>
          <ul>
            {surelevation.buildingHold && (
              <li className="surelevation-card__info">
                Emprise du bâti en m² :
                <div className="surelevation-card__info__data">
                  {StringUtils.meterSquareFormater(
                    Math.round(surelevation.buildingHold)
                  )}
                </div>
              </li>
            )}
            {surelevation.currentLevels && (
              <li className="surelevation-card__info">
                Nombre de plancher existant :
                <div className="surelevation-card__info__data">
                  {surelevation.currentLevels}
                </div>
              </li>
            )}
            {surelevation.maximumLevels && (
              <li className="surelevation-card__info">
                Nombre de plancher constructible :
                <div className="surelevation-card__info__data">
                  {surelevation.potentielElevationLevels}
                </div>
              </li>
            )}

            <li className="surelevation-card__info">
              Largeur de la voie :
              <div className="surelevation-card__info__data surelevation-card__blur">
                {priseAuSol} m
              </div>
            </li>
          </ul>
          {detailed ? (
            <>
              <div className="surelevation-card__more-fields">
                23 autres informations clés
                <div className="surelevation-card__more-fields__sub">
                  à saisir dès maintenant
                </div>
              </div>
              <div
                className="uppy-button uppy-button--primary surelevation-card__cta"
                onClick={handleAcquireProjectClick}
              >
                Acquérir le dossier
              </div>
            </>
          ) : (
            <div
              className="uppy-button uppy-button--primary surelevation-card__cta"
              onClick={handleSeeMoreDetailsClick}
            >
              Voir plus de détails
            </div>
          )}
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit} className="surelevation-card__form">
            <div className="uppy-field">
              <input
                placeholder="Votre prénom *"
                type="text"
                className="uppy-input"
                name="given-name"
                required
                value={formValues.firstName}
                onChange={handleFirstNameChange}
              />
            </div>
            <div className="uppy-field">
              <input
                placeholder="Votre nom *"
                type="text"
                className="uppy-input"
                name="family-name"
                required
                value={formValues.lastName}
                onChange={handleLastNameChange}
              />
            </div>
            <div className="uppy-field">
              <input
                placeholder="Votre mail *"
                type="email"
                className="uppy-input"
                name="email"
                required
                value={formValues.email}
                onChange={handleEmailNameChange}
              />
            </div>
            <div className="uppy-field">
              <input
                placeholder="Votre téléphone"
                type="phone"
                className="uppy-input"
                name="tel"
                value={formValues.phone}
                onChange={handlePhoneChange}
              />
            </div>

            <button
              type="submit"
              className="uppy-button uppy-button--primary surelevation-card__cta"
            >
              Envoyer ma demande
            </button>

            {submitSuccess && (
              <div className="surelevation-card__form__success">
                <div className="surelevation-card__form__success__icon">
                  <CheckCircle />
                </div>
                <div className="surelevation-card__form__success__text">
                  Nous avons bien reçu votre demande, nous reviendrons vers vous
                  très vite
                </div>
              </div>
            )}
          </form>

          <div
            className="uppy-button uppy-button--shadow surelevation-card__cta"
            onClick={handleReturnToTechnicalInfosClick}
          >
            Retouner à la fiche technique
          </div>
        </>
      )}
    </div>
  );
};
