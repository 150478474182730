export enum ProprietaryType {
  Company = 'Unipropriétaire - Société',
  Copro = 'Copro',
  People = 'Unipropriétaire Habitant',
  SCI = 'SCI',
}

export const proprietaryTypeTranslations = {
  [ProprietaryType.Company]: 'Société',
  [ProprietaryType.Copro]: 'Copropriété',
  [ProprietaryType.People]: 'Personne physique',
  [ProprietaryType.SCI]: 'SCI',
};
